import Vue from 'vue'

// axios
import axios from 'axios'

const that = Vue.prototype

if (that.state.token) {
    // console.log('I have token : ', that.state.token)
    axios.defaults.headers.common['Authorization'] = that.state.token
}

// axios.defaults.headers.common['Content-Type'] = 'application/json'

// axios.defaults.baseURL = 'http://ba.test/api'
axios.defaults.baseURL = 'https://dex.coinrace.website/api'

axios.interceptors.response.use(
    (res) => {
        that.state.loading = false

        return res;
    },
    async (err) => {
        let html_text = '';

        that.state.loading = false

        const res = err.response || err.toJSON()

        /*console.warn(
            //     'config : ', err.config,
            //     'code : ', err.code,
            'request : ', err.request,
            //     'response : ', err.response,
            //     'isAxios : ', err.isAxiosError,
            //     'json : ', err.toJSON()
        )*/

        if (res.config.responseType === 'blob') {
            const vm = that
            let reader = new FileReader();
            reader.onload = function (e) {
                let test = JSON.parse(e.target.result);
                vm.$error('', vm.$errorHandeling[test.message])
            };
            reader.readAsText(err.response.data);
            return Promise.reject(err);
        }

        if (err.response) if (res.data.message) if (res.data.message.includes('JWT') || res.data.message.includes('Invalid Token')) {
            that.$logout()
            return Promise.reject(err);
        }

        if (err.response ? ['UNAUTHORIZED'].includes(res.data.status) : false) {
            return Promise.reject(err);
        }

        if (err.response ? ['Validation Error'].includes(res.data.message) : false) {
            return Promise.reject(err);
        }

        if (res.data.message === 'Market Is Inactive') {
            that.$error('بازار غیر فعال است')
            return Promise.reject(err);
        }

        let text = err.response ?
            that.$errorHandeling[err.response.data.message]
            || that.$errorHandeling[err.response.data.status]
            || err.response.data.message
            : res.message
        if (err.response.data.errors) {
            for (let item in res.data.errors) {
                html_text += '<p>' + res.data.errors[item][0] + '</p>';
            }
        }

        that.$swal({
            icon: 'error',
            title: err.request.status,
            text: text,
            html: html_text,
            confirmButtonText: 'متوجه شدم',
            customClass: {
                confirmButton: 'btn btn-success',
            },
        })

        return Promise.reject(err);
    },);

that.$axios = axios

export default axios
