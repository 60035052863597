// import Vue from 'vue'
// const that = Vue.prototype

export default [
    {
        title: 'داشبورد',
        route: 'Dashboard',
        icon: 'HomeIcon',
        role: ['all']
    },
    {
        title: 'کاربران سایت',
        route: 'Accounts',
        icon: 'UsersIcon',
        role: ['LIST_USERS']
    },
    {
        title: 'مدیریت سامانه',
        icon: 'MonitorIcon',
        role: ['LIST_ADMINS', 'LIST_ROLES'],
        children: [
            {
                title: 'مدیران',
                route: 'admin-accounts',
                role: ['LIST_ADMINS']
            },
            {
                title: 'نقش ها و دسترسی ها',
                route: 'admin-roles',
                role: ['LIST_ROLES']
            },
            {
                title: 'افزودن نقش',
                route: 'add-role',
                role: ['CREATE_ROLES']
            },
            {
                title: 'مشاهده نقش',
                route: 'show-role',
                role: ['UPDATE_ROLES'],
                disabled: true
            },
        ],
    },
    {
        title: 'مدیریت شبکه ها',
        route: 'Chain',
        icon: 'DatabaseIcon',
        role: ['all'],
    },
    {
        title: 'مدیریت توکن ها',
        route: 'Token',
        icon: 'CodepenIcon',
        role: ['all'],
    },
    {
        title: 'تاریخچه سواپ',
        route: 'Swap',
        icon: 'RepeatIcon',
        role: ['all'],
    },
    {
        title: 'تاریخچه حساب ها',
        route: 'Account',
        icon: 'CreditCardIcon',
        role: ['all'],
    },
    // {
    //     title: 'مدیریت تتر',
    //     route: 'dollar',
    //     icon: 'DollarSignIcon',
    //     role: ['LIST_DOLLARS'],
    // },

    {
        title: 'تنظیمات سایت',
        icon: 'ToolIcon',
        role: ['LIST_PAGE'],
        children: [
            // {
            //     title: 'قوانین',
            //     route: 'Policy',
            // },
            {
                title: 'اطلاعات سایت',
                route: 'SiteInfo',
                role: ['UPDATE_PAGE'],
            },
            {
                title: 'راهنمای استفاده',
                route: 'HowToUse',
                role: ['UPDATE_PAGE'],
            },
            {
                title: 'افزودن راهنما',
                route: 'NewHelp',
                role: ['UPDATE_PAGE']
            },
            {
                title: 'مشاهده راهنما',
                route: 'ShowHelp',
                role: ['UPDATE_PAGE'],
                disabled: true
            },
            {
                title: 'اسلایدر',
                route: 'Slider',
                role: ['UPDATE_PAGE'],
            },
            {
              title : 'صفحه درباره ما',
              route : 'AboutUs',
                role: ['all'],
                children : [
                    {
                        title: 'ویرایش بخش درباره ما',
                        route: 'AboutUs',
                        role: ['UPDATE_PAGE'],
                    },
                    {
                        title: 'ویرایش بخش ماموریت ها',
                        route: 'Mission',
                        role: ['UPDATE_PAGE'],
                    }
                ]
            },
            {
                title: 'سوالات متداول',
                route: 'FAQ',
                role: ['UPDATE_PAGE'],
            },
        ],
    },
]

